export interface Card {
  heading: string;
  icon: string;
  text: string;
  tags: string[];
}

export const phoneNumber = '+91 7349432553';
export interface Milestone {
  id: string;
  ref: string;
  milestone: string;
  heading: string;
  cards?: Card[]; // Make it optional for Milestone
}

export interface SpecialisationElective {
  specialisationTopic: string;
  id: string;
  cards: Card[];
}

export interface SpecialisationMilestone extends Milestone {
  specialisationElectives?: SpecialisationElective[];
}

export interface Achievement {
  heading: {
    text: string;
    color: string;
    icon: string;
  };
  content: string[];
  image: string;
}

export interface Year {
  id: string;
  yearName: string;
  tagLine: string;
  milestones: SpecialisationMilestone[];
  achievementCard: Achievement;
}

export const oneYearCourse: Year[] = [
  {
    id: 'sphurti-1-year-sprint',
    yearName: 'Foundation Year',
    tagLine: 'Empowering Skills For The Digital Era',
    milestones: [
      {
        id: '1001',
        ref: 'Ref5',
        milestone: 'Milestone 1',
        heading: 'Getting Started with Specialization',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack In Cloud',
            id: '4100',
            cards: [
              {
                heading: 'Web App Architectures',
                icon: '/images/campusEdgeCourseDescription/web/web-app-arch.svg',
                text: `Explore Web App Architectures: Understand Monolithic, Microservices, Serverless & SPA Approaches for Scalable Web Apps`,
                tags: [
                  'Monolithic',
                  'Microservices',
                  'Single Page Apps',
                  'Progressive Web Apps',
                  'Serverless',
                ],
              },
              {
                heading: 'Cloud Concepts',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Explore Cloud Concepts: Learn Cloud Models, Services (AWS/Azure/GCP), Deployment, Scalability & Cost Optimisation`,
                tags: [
                  'Explore Cloud Concepts',
                  'Learn Cloud Models',
                  'Services (AWS/Azure/GCP)',
                  'Deployment',
                  'Scalability & Cost Optimisation',
                ],
              },
              {
                heading: 'Front-End Development Using React',
                icon: '/images/campusEdgeCourseDescription/web/react.svg',
                text: `Master React Front-End Development: Learn React Components, State Management, Routing, API Integration & Interactive UIs`,
                tags: [
                  'React Basics',
                  'React-Router',
                  'Redux Toolkit',
                  'State Management',
                  'Routing and Navigation',
                ],
              },
              {
                heading: 'Deploy A Front-End App',
                icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
                text: `Build & Deploy Front-End Apps on Cloud Platforms, Domain Setup, ENV Variables, Optimisation & More`,
                tags: ['Build & Deploy Front-End App on Versal', 'Netlify', 'AWS', 'Azure', 'etc.'],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Java Advanced concepts',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Master Backend Technologies: Learn JDBC, Hibernate, JPA, Servlet, and MySQL for building robust and scalable applications.',
                tags: ['Knowledge About DB/ORM & Servlet', 'Relational Database Concepts', 'JDBC'],
              },
              {
                heading: 'Java Build Tools',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Explore Essential Dev Tools: Dive into DB, Git, Spring, Spring Boot & more to empower your software development journey.',
                tags: [
                  'Develop Skills On How To Use Tools',
                  'Database Management Systems (DBMS)',
                  'Git Basics',
                ],
              },
              {
                heading: 'Introduction To Spring Framework',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Learn Spring Frame Work Including Spring Core, Spring DataJPA, Spring Rest & Microservices',
                tags: ['Learn Real-Time Projects Via MVC Approach', 'Bean Lifecycle and Scopes'],
              },
              {
                heading: 'Getting Started With Spring Boot',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Spring Boot Project Setup: Master setting up project files & importing in IDE for seamless development.',
                tags: [
                  'Understand Spring Boot',
                  'Choose Dependencies',
                  'IDE Integration',
                  'Application Configuration',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4300', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Python Libraries',
                icon: '/images/campusEdgeCourseDescription/dataScience/python.svg',
                text: "Maximize Python's Power! Learn NumPy, Pandas, Matplotlib, Seaborn, Beautiful Soup, Requests, Flask, Fastai & More",
                tags: [
                  'NumPy',
                  'Pandas',
                  'Matplotlib',
                  'Seaborn',
                  'Requests',
                  'Beautiful Soup',
                  'Web Interactions',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Build A Solid Math Foundation For Data Science: Arithmetic, Algebra & Geometry. Perfect For Beginners.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Discover Statistics: Your Gateway To Data Insights. Master The Essentials For Data-Driven Decisions',
                tags: [
                  'Statistics',
                  'Probability',
                  'Hypothesis Testing',
                  'Regression Analysis',
                  'ANOVA',
                  'Data Analysis',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Model Tuning & Deployment With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4400', // Replace with the actual ID
            cards: [
              {
                heading: 'SQL',
                icon: '/images/campusEdgeCourseDescription/dbms/sql.svg',
                text: 'Master SQL For Data Analytics - Basic To Advanced. Learn MySQL, Interact With Databases & Make Data-Driven Decisions',
                tags: [
                  'SQL Commands',
                  'SQL Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Data Analysis Mastery: Build strong math foundations in Arithmetic, Algebra & Geometry for advanced data insights.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Data Analytics & Statistics: Acquire crucial statistical skills to derive meaningful insights from data.',
                tags: [
                  'Statistics',
                  'Probability',
                  'Probability Distributions',
                  'Inferential Statistics',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Evaluation & Optimisation With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1002',
        ref: 'Ref6',
        milestone: 'Milestone 2',
        heading: 'In-Depth Specialization',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '3100',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Explore Advanced Database Concepts: Optimise, Index, Transact, Shard & Replicate Data For Sophisticated Apps`,
                tags: ['NoSQL Databases - MongoDB', 'Database Concepts', 'Database Optimization'],
              },
              {
                heading: 'Back End Development Using Express',
                icon: '/images/campusEdgeCourseDescription/misc/express.svg',
                text: `Learn Express.js For Back End: Routing, Middleware, DB Integration, Authentication & RESTful API Creation For Robust Servers`,
                tags: ['Express.js - Minimal Node.js and Flexible Node.js Web App Framework'],
              },
              {
                heading: 'Integration Of Back End with Front End',
                icon: '/images/campusEdgeCourseDescription/misc/integration.svg',
                text: `Learn Back End-Front End Integration: Connect Back End (Express/Node.js) to React Front End For Seamless Data Exchange & Interaction`,
                tags: ['Axios - Promise Based HTTP Client For The Browser & Node.js skills'],
              },
              {
                heading: 'Deploying The App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Build & Deploy Back end Apps on Cloud Platforms, Domain Setup, Environment Variables Setup, Optimisation techniques & more`,
                tags: ['Build & Deployment Back end App on Railway, Render, AWS, Azure, etc.'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Capstone: Develop MERN App, Showcase Skills In Front End, Back End, Databases & Deployment`,
                tags: [
                  'Practical Exposure To MERN',
                  'MongoDB',
                  'Express.js',
                  'React',
                  'Node.js skills',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Backend development Java',
            id: '3200',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Learn About JDBC Object-Relational Mapping (ORM), Connection Pooling, Caching & Knowing Advanced Concepts Of DB`,
                tags: [
                  'Thorough Knowledge On DBMS & Object-Relational Mapping (ORM)',
                  'Batch Processing,',
                ],
              },
              {
                heading: 'Developement Of Restful APIs',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Learn To Create RESTful APIs In Java Using HTTP Methods & URLs For CRUD Operations On Resources`,
                tags: [
                  'Learn To Build Custom API',
                  'RESTful Architecture and Principles',
                  'Java Web Framework',
                ],
              },
              {
                heading: 'Spring Security',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: `Spring Security: Powerful Java Security Framework For Web Apps, Handling Authentication, Authorisation & More`,
                tags: [
                  'Gain Knowledge On Security',
                  'Authentication and Authorization',
                  'User Authentication',
                ],
              },
              {
                heading: 'Deploying App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Deploy A Java App on The Cloud Involving Hosting & Running Your Java App on Cloud Infrastructure Provided By Cloud Service Providers`,
                tags: ['Deploying Java Applications', 'Monitoring and Logging', 'Load Balancing'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Java Capstone: Showcase Real-World Skills With A Comprehensive Project, Culminating In Academic Learning`,
                tags: [
                  'Build An Application',
                  'Project Planning and Management',
                  'Programming Proficiency',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '3300',
            cards: [
              {
                heading: 'Machine Learning ',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: `Discover The Potential Of Machine Learning! Build Predictive Models, Explore Data Patterns & Master ML`,
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Feature Engineering',
                  'Hyperparameter Tuning',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Master SQL & MongoDB: Comprehensive Database Course covering Structured & NoSQL Databases for Efficient Data Management',
                tags: [
                  'SQL',
                  'Database Design',
                  'Data Modeling',
                  'Data Manipulation',
                  'MongoDB',
                  'NoSQL & Data Retrieval',
                ],
              },
              {
                heading: 'Time Series',
                icon: '/images/campusEdgeCourseDescription/dataScience/time-series.svg',
                text: 'Explore Time-Dependent Data Analysis & Forecasting with ARIMA, SARIMA, Prophet & More. Master Valuable Insights From Time Series Data.',
                tags: [
                  'Time-Dependent Data',
                  'Forecasting',
                  'Trends',
                  'Seasonality',
                  'ARIMA',
                  'SARIMA',
                ],
              },
              {
                heading: 'Deep Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/deep-learning.svg',
                text: 'Immerse In Deep Learning: Neural Networks, CNNs, RNNs, Transformers, Pre-Trained Models & More',
                tags: [
                  'Neural Networks',
                  'Activation Functions',
                  'Backpropagation',
                  'CNNs',
                  'RNNs',
                  'Transfer Learning',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'Immerse in Neural Machine Translation: Build Powerful Language Systems, Train Transformers, Enhance Multilingual Communication',
                tags: [
                  'Multilingual Data',
                  'Model Evaluation & Building Language Translation Systems',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '3400',
            cards: [
              {
                heading: 'Machine Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Empower With ML: Master Supervised & Unsupervised Learning, Hyperparameter Tuning, Model Development & Serving',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Learn MySql: SQL Basics, Data Types, Loading, Constraints, Joins, Functions, Views, Triggers & more',
                tags: [
                  'SQL Commands',
                  'Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Business Intelligence Tools',
                icon: '/images/campusEdgeCourseDescription/dataScience/business-intelligence.svg',
                text: 'Master Data Visualization: Harness the power of Power BI & Tableau for impactful data presentations.',
                tags: [
                  'Data Visualization',
                  'Data Preparation',
                  'Data Blending',
                  'Dashboard Creation',
                  'Data Exploration',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'In The Capstone Project, Apply All Acquired Skills To Work On An Industry-Relevant Project',
                tags: ['Industry-Relevant Project', 'Hands-On Learning & Becoming Industry-Ready'],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 1',
        color: '#94BE8A',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Proficiency in specialization',
        'Choose from the 4 available options',
        'Work on End to End Projects',
        'Live Learning',
        'Practice Questions from the experts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year1.svg',
    },
  },
];

export const twoYearCourse: Year[] = [
  {
    id: 'samridhi-2-year-pathway',
    yearName: 'Foundation Year',
    tagLine: 'Empowering Skills For The Digital Era',
    milestones: [
      {
        id: '1001',
        ref: 'Ref5',
        milestone: 'Milestone 1',
        heading: 'Getting Started with Specialization',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack In Cloud',
            id: '4100',
            cards: [
              {
                heading: 'Web App Architectures',
                icon: '/images/campusEdgeCourseDescription/web/web-app-arch.svg',
                text: `Explore Web App Architectures: Understand Monolithic, Microservices, Serverless & SPA Approaches for Scalable Web Apps`,
                tags: [
                  'Monolithic',
                  'Microservices',
                  'Single Page Apps',
                  'Progressive Web Apps',
                  'Serverless',
                ],
              },
              {
                heading: 'Cloud Concepts',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Explore Cloud Concepts: Learn Cloud Models, Services (AWS/Azure/GCP), Deployment, Scalability & Cost Optimisation`,
                tags: [
                  'Explore Cloud Concepts',
                  'Learn Cloud Models',
                  'Services (AWS/Azure/GCP)',
                  'Deployment',
                  'Scalability & Cost Optimisation',
                ],
              },
              {
                heading: 'Front-End Development Using React',
                icon: '/images/campusEdgeCourseDescription/web/react.svg',
                text: `Master React Front-End Development: Learn React Components, State Management, Routing, API Integration & Interactive UIs`,
                tags: [
                  'React Basics',
                  'React-Router',
                  'Redux Toolkit',
                  'State Management',
                  'Routing and Navigation',
                ],
              },
              {
                heading: 'Deploy A Front-End App',
                icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
                text: `Build & Deploy Front-End Apps on Cloud Platforms, Domain Setup, ENV Variables, Optimisation & More`,
                tags: ['Build & Deploy Front-End App on Versal', 'Netlify', 'AWS', 'Azure', 'etc.'],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Java Advanced concepts',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Master Backend Technologies: Learn JDBC, Hibernate, JPA, Servlet, and MySQL for building robust and scalable applications.',
                tags: ['Knowledge About DB/ORM & Servlet', 'Relational Database Concepts', 'JDBC'],
              },
              {
                heading: 'Java Build Tools',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Explore Essential Dev Tools: Dive into DB, Git, Spring, Spring Boot & more to empower your software development journey.',
                tags: [
                  'Develop Skills On How To Use Tools',
                  'Database Management Systems (DBMS)',
                  'Git Basics',
                ],
              },
              {
                heading: 'Introduction To Spring Framework',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Learn Spring Frame Work Including Spring Core, Spring DataJPA, Spring Rest & Microservices',
                tags: ['Learn Real-Time Projects Via MVC Approach', 'Bean Lifecycle and Scopes'],
              },
              {
                heading: 'Getting Started With Spring Boot',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Spring Boot Project Setup: Master setting up project files & importing in IDE for seamless development.',
                tags: [
                  'Understand Spring Boot',
                  'Choose Dependencies',
                  'IDE Integration',
                  'Application Configuration',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4300', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Python Libraries',
                icon: '/images/campusEdgeCourseDescription/dataScience/python.svg',
                text: "Maximize Python's Power! Learn NumPy, Pandas, Matplotlib, Seaborn, Beautiful Soup, Requests, Flask, Fastai & More",
                tags: [
                  'NumPy',
                  'Pandas',
                  'Matplotlib',
                  'Seaborn',
                  'Requests',
                  'Beautiful Soup',
                  'Web Interactions',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Build A Solid Math Foundation For Data Science: Arithmetic, Algebra & Geometry. Perfect For Beginners.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Discover Statistics: Your Gateway To Data Insights. Master The Essentials For Data-Driven Decisions',
                tags: [
                  'Statistics',
                  'Probability',
                  'Hypothesis Testing',
                  'Regression Analysis',
                  'ANOVA',
                  'Data Analysis',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Model Tuning & Deployment With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4400', // Replace with the actual ID
            cards: [
              {
                heading: 'SQL',
                icon: '/images/campusEdgeCourseDescription/dbms/sql.svg',
                text: 'Master SQL For Data Analytics - Basic To Advanced. Learn MySQL, Interact With Databases & Make Data-Driven Decisions',
                tags: [
                  'SQL Commands',
                  'SQL Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Data Analysis Mastery: Build strong math foundations in Arithmetic, Algebra & Geometry for advanced data insights.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Data Analytics & Statistics: Acquire crucial statistical skills to derive meaningful insights from data.',
                tags: [
                  'Statistics',
                  'Probability',
                  'Probability Distributions',
                  'Inferential Statistics',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Evaluation & Optimisation With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1002',
        ref: 'Ref6',
        milestone: 'Milestone 2',
        heading: 'In-Depth Specialization',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '3100',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Explore Advanced Database Concepts: Optimise, Index, Transact, Shard & Replicate Data For Sophisticated Apps`,
                tags: ['NoSQL Databases - MongoDB', 'Database Concepts', 'Database Optimization'],
              },
              {
                heading: 'Back End Development Using Express',
                icon: '/images/campusEdgeCourseDescription/misc/express.svg',
                text: `Learn Express.js For Back End: Routing, Middleware, DB Integration, Authentication & RESTful API Creation For Robust Servers`,
                tags: ['Express.js - Minimal Node.js and Flexible Node.js Web App Framework'],
              },
              {
                heading: 'Integration Of Back End with Front End',
                icon: '/images/campusEdgeCourseDescription/misc/integration.svg',
                text: `Learn Back End-Front End Integration: Connect Back End (Express/Node.js) to React Front End For Seamless Data Exchange & Interaction`,
                tags: ['Axios - Promise Based HTTP Client For The Browser & Node.js skills'],
              },
              {
                heading: 'Deploying The App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Build & Deploy Back end Apps on Cloud Platforms, Domain Setup, Environment Variables Setup, Optimisation techniques & more`,
                tags: ['Build & Deployment Back end App on Railway, Render, AWS, Azure, etc.'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Capstone: Develop MERN App, Showcase Skills In Front End, Back End, Databases & Deployment`,
                tags: [
                  'Practical Exposure To MERN',
                  'MongoDB',
                  'Express.js',
                  'React',
                  'Node.js skills',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Backend development Java',
            id: '3200',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Learn About JDBC Object-Relational Mapping (ORM), Connection Pooling, Caching & Knowing Advanced Concepts Of DB`,
                tags: [
                  'Thorough Knowledge On DBMS & Object-Relational Mapping (ORM)',
                  'Batch Processing,',
                ],
              },
              {
                heading: 'Developement Of Restful APIs',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Learn To Create RESTful APIs In Java Using HTTP Methods & URLs For CRUD Operations On Resources`,
                tags: [
                  'Learn To Build Custom API',
                  'RESTful Architecture and Principles',
                  'Java Web Framework',
                ],
              },
              {
                heading: 'Spring Security',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: `Spring Security: Powerful Java Security Framework For Web Apps, Handling Authentication, Authorisation & More`,
                tags: [
                  'Gain Knowledge On Security',
                  'Authentication and Authorization',
                  'User Authentication',
                ],
              },
              {
                heading: 'Deploying App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Deploy A Java App on The Cloud Involving Hosting & Running Your Java App on Cloud Infrastructure Provided By Cloud Service Providers`,
                tags: ['Deploying Java Applications', 'Monitoring and Logging', 'Load Balancing'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Java Capstone: Showcase Real-World Skills With A Comprehensive Project, Culminating In Academic Learning`,
                tags: [
                  'Build An Application',
                  'Project Planning and Management',
                  'Programming Proficiency',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '3300',
            cards: [
              {
                heading: 'Machine Learning ',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: `Discover The Potential Of Machine Learning! Build Predictive Models, Explore Data Patterns & Master ML`,
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Feature Engineering',
                  'Hyperparameter Tuning',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Master SQL & MongoDB: Comprehensive Database Course covering Structured & NoSQL Databases for Efficient Data Management',
                tags: [
                  'SQL',
                  'Database Design',
                  'Data Modeling',
                  'Data Manipulation',
                  'MongoDB',
                  'NoSQL & Data Retrieval',
                ],
              },
              {
                heading: 'Time Series',
                icon: '/images/campusEdgeCourseDescription/dataScience/time-series.svg',
                text: 'Explore Time-Dependent Data Analysis & Forecasting with ARIMA, SARIMA, Prophet & More. Master Valuable Insights From Time Series Data.',
                tags: [
                  'Time-Dependent Data',
                  'Forecasting',
                  'Trends',
                  'Seasonality',
                  'ARIMA',
                  'SARIMA',
                ],
              },
              {
                heading: 'Deep Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/deep-learning.svg',
                text: 'Immerse In Deep Learning: Neural Networks, CNNs, RNNs, Transformers, Pre-Trained Models & More',
                tags: [
                  'Neural Networks',
                  'Activation Functions',
                  'Backpropagation',
                  'CNNs',
                  'RNNs',
                  'Transfer Learning',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'Immerse in Neural Machine Translation: Build Powerful Language Systems, Train Transformers, Enhance Multilingual Communication',
                tags: [
                  'Multilingual Data',
                  'Model Evaluation & Building Language Translation Systems',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '3400',
            cards: [
              {
                heading: 'Machine Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Empower With ML: Master Supervised & Unsupervised Learning, Hyperparameter Tuning, Model Development & Serving',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Learn MySql: SQL Basics, Data Types, Loading, Constraints, Joins, Functions, Views, Triggers & more',
                tags: [
                  'SQL Commands',
                  'Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Business Intelligence Tools',
                icon: '/images/campusEdgeCourseDescription/dataScience/business-intelligence.svg',
                text: 'Master Data Visualization: Harness the power of Power BI & Tableau for impactful data presentations.',
                tags: [
                  'Data Visualization',
                  'Data Preparation',
                  'Data Blending',
                  'Dashboard Creation',
                  'Data Exploration',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'In The Capstone Project, Apply All Acquired Skills To Work On An Industry-Relevant Project',
                tags: ['Industry-Relevant Project', 'Hands-On Learning & Becoming Industry-Ready'],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 1',
        color: '#94BE8A',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Proficiency in specialization',
        'Choose from the 4 available options',
        'Work on End to End Projects',
        'Live Learning',
        'Practice Questions from the experts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year1.svg',
    },
  },
  {
    id: '2000',
    yearName: 'Advanced Year',
    tagLine: 'Your Gateway To Success: Revise, Refine, Excel',
    milestones: [
      {
        id: '4001',
        ref: 'Ref5',
        milestone: 'Milestone 3',
        heading: 'Getting Upskilled',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees. Ace interviews & Boost Problem-Solving.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Web Dev Mastery: Delve into core concepts, ace interviews, and excel in the world of web development.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },

              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees covered.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Web Dev Excellence: Dive into core concepts, ace interviews, and excel in your domain.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },

              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees. Ace interviews & Boost Problem-Solving.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Dive Into Web Dev, Master Core Concepts & Ace Interviews With Practice. Excel In Your Domain.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },
              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees. Ace interviews & Boost Problem-Solving.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Delve Into The Intricacies Of Data Analytics & Practice A Wide Array Of Interview Questions.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },
              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '4002',
        ref: 'Ref6',
        milestone: 'Milestone 4',
        heading: 'Getting Job Ready',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '4200',
            cards: [
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Get access to tailored placement opportunities matching your skills and career goals. Unlock your potential!`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200',
            cards: [
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Discover placement opportunities that match your skills and career goals, ensuring the perfect fit for your future success.`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4200',
            cards: [
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Explore tailored placement opportunities that align with your skills and career goals for a successful future.`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4200',
            cards: [
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Unlock your potential with access to personalized placement opportunities aligning with your skills and career aspirations.`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 2',
        color: '#FFDBD3',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Empowered for Success',
        'Build Your Professional Profile',
        'Practice Interview Questions',
        'Revisit the concepts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year2.svg',
    },
  },
];

export const threeYearCourse: Year[] = [
  {
    id: 'samarthaya-3-year-route',
    yearName: 'Foundation Year',
    tagLine: 'Building Strong Foundations in Java Programming',
    milestones: [
      {
        id: '1001',
        ref: 'Ref1',
        milestone: 'Milestone 1',
        heading: 'Getting Started with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'JAVA',
            id: '1100',
            cards: [
              {
                heading: 'Computer Fundamentals',
                icon: '/images/campusEdgeCourseDescription/misc/computer-fundamentals.svg',
                text: `Learn Computer Concepts, Programming History & Build A Strong Understanding Of Programming`,
                tags: [
                  'Random Access Memory / Read Only Memory',
                  'Memory Storage',
                  'Programming History',
                ],
              },
              {
                heading: 'Getting Started With Programming',
                icon: '/images/campusEdgeCourseDescription/misc/getting-started-with-programming.svg',
                text: `Embark on your Java journey: Explore its rich history, discover its market impact, and embrace its enduring importance.`,
                tags: [
                  'Mastering the WORA (Write Once Run Anywhere) Concepts',
                  'JAVA history',
                  'Market Impact',
                ],
              },
              {
                heading: 'JAVA Core',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: `Master Core Java: Gain expertise in syntax and programming concepts to build a strong foundation in Java development.`,
                tags: [
                  'Java Syntax',
                  'Core Java Insights',
                  'Build Strong Foundation',
                  'Java Programming Concepts',
                ],
              },
              {
                heading: 'Core Data Structure',
                icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
                text: `Boost Logical Thinking & Optimize Programs: Explore DSA to enhance programming skills and master the art of program optimization.`,
                tags: [
                  'Core Data Structure',
                  'Optimized Code',
                  'Logical Ability',
                  'Problem-Solving Skills',
                ],
              },
              {
                heading: 'Git Tool',
                icon: '/images/campusEdgeCourseDescription/git/git-tool.svg',
                text: `Explore Industrial Project Sharing & File Storage Repo: Learn how to manage Java files efficiently for collaborative development. `,
                tags: [
                  'Java File Management',
                  'Java Code Collaboration',
                  'Industrial Project Sharing',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1002',
        ref: 'Ref2',
        milestone: 'Milestone 2',
        heading: 'Unlocking DSA with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'JAVA',
            id: '4100',
            cards: [
              {
                heading: 'Linear Data Structures',
                icon: '/images/campusEdgeCourseDescription/dsa/linear.svg',
                text: `Dive into Arrays, Linked Lists, Stacks, Queues & More: Explore key data structures and topics for comprehensive programming skills.`,
                tags: [
                  'Arrays in Java',
                  'Stack',
                  'Queue',
                  'Linked List',
                  'Hashmap',
                  'Explore Linear Data Structures',
                ],
              },
              {
                heading: 'Non-Linear Data Structures',
                icon: '/images/campusEdgeCourseDescription/dsa/non-linear.svg',
                text: `Master Tree Data Structures & Non-Linear Concepts: Gain insights with examples for a solid grasp of advanced data structures.`,
                tags: [
                  'Tree',
                  'Graphs',
                  'Minheap',
                  'Maxheap',
                  'Understanding Basic Data Structures',
                  'B-Trees',
                ],
              },
              {
                heading: 'Advanced Algorithms',
                icon: '/images/campusEdgeCourseDescription/dsa/advanced-algo.svg',
                text: `Explore Graphs & Dynamic Programming: Learn advanced topics for solving complex problems with efficiency and elegance. `,
                tags: [
                  'Greedy Algo',
                  'Dynamic Programming',
                  'Graph Representation',
                  'Network Flow Algorithms',
                ],
              },
              {
                heading: 'DSA In Practice',
                icon: '/images/campusEdgeCourseDescription/dsa/dsa.svg',
                text: `Sharpen Problem-Solving Skills: Tackle LeetCode challenges to enhance your coding abilities and crack coding interviews.`,
                tags: [
                  'Interview Questions',
                  'Leetcode',
                  'Live Demonstrations',
                  'Problem Decomposition',
                ],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 1',
        color: '#94BE8A',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Gain Expertise In JAVA Programming',
        'Learn from Basics',
        'Master Data Structures',
        'Practice Questions prepared by Experts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year1.svg',
    },
  },
  {
    id: '2000',
    yearName: 'Advanced Year',
    tagLine: 'Transform Ideas Into Interactive Experiences',
    milestones: [
      {
        id: '2001',
        ref: 'Ref3',
        milestone: 'Milestone 3',
        heading: 'Getting Started With Web Dev',
        cards: [
          {
            heading: 'Operating Systems',
            icon: '/images/campusEdgeCourseDescription/misc/computer-fundamentals.svg',
            text: `Master OS Essentials: Processes, Memory, Files, Concurrency For Advanced System Admin Skills`,
            tags: [
              'Process Management',
              'Memory Management',
              'Concurrency Management',
              'Deadlock',
              'File System',
            ],
          },
          {
            heading: 'Networking',
            icon: '/images/campusEdgeCourseDescription/web/networking.svg',
            text: `Explore Networking Basics: Protocols, IP Addressing, Routing, DNS & Security To Set up & Manage Robust Networks`,
            tags: ['OSI and TCP/IP', 'Different Layers Of OSI Model', 'Network Protocols'],
          },
          {
            heading: 'Data bases - RDBMS',
            icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
            text: `Learn RDBMS Fundamentals: Database Design, Normalisation, Transactions & Data Management For Data-Driven Apps`,
            tags: [
              'Relational Databases',
              'Schemas',
              'ER Model',
              'Normalization',
              'ACID Transactions',
            ],
          },
          {
            heading: 'SQL Queries',
            icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
            text: `Create & Perform Operations On Relational Databases: Insert, Update, Delete, JOINS, etc.`,
            tags: ['Different SQL Commands - DDL, DML, DCL', 'CRUD Operations', 'Querying Data'],
          },
          {
            heading: 'Web Applications',
            icon: '/images/campusEdgeCourseDescription/web/web-app.svg',
            text: `Web App Dev: Front-End (HTML, CSS, JS), Back-End (Node.js), Databases, Deployment - Building Dynamic Sites`,
            tags: [
              'Web Application Tools & Languages',
              'Front-End Development',
              'Back-End Development (Node.js)',
            ],
          },
        ],
      },
      {
        id: '2002',
        ref: 'Ref4',
        milestone: 'Milestone 4',
        heading: 'Exploring Web and App Development',
        cards: [
          {
            heading: 'HTML',
            icon: '/images/campusEdgeCourseDescription/web/html.svg',
            text: `Explore HTML Essentials: Structure, Tags, Forms, Multimedia & Semantics To Build Web Pages`,
            tags: [
              'Basic HTML Structure',
              'Web Accessibility and SEO',
              'HTML Tags and Elements',
              'HTML5 Features',
            ],
          },
          {
            heading: 'CSS',
            icon: '/images/campusEdgeCourseDescription/web/css.svg',
            text: `Master CSS: Selectors, Colors, Typography, Positioning, Layout, Responsive Design & More`,
            tags: ['CSS Basics', 'UI Frameworks', 'Styling Elements', 'Layout and Positioning'],
          },
          {
            heading: 'JS',
            icon: '/images/campusEdgeCourseDescription/web/js.svg',
            text: `Learn Interactive Web Programming With JavaScript - Basic To Advanced (DOM, Prototypes, Classes)`,
            tags: ['JavaScript Programming Concepts', 'DOM Manipulation', 'Web APIs Skills'],
          },
          {
            heading: 'Node.js',
            icon: '/images/campusEdgeCourseDescription/web/node.svg',
            text: `Master Node.js: Web APIs, Real-Time Comm., Build Scalable Apps & Server-Side JS Course`,
            tags: [
              'Server',
              'MVC Architecture',
              'Node Modules (Path, OS, etc.)',
              'Creating REST APIs',
            ],
          },
          {
            heading: 'App Development',
            icon: '/images/campusEdgeCourseDescription/web/app-dev.svg',
            text: `Explore MERN App Dev: Plan, Design & Code Web Apps Using MongoDB, Express, React, & Node.js For Full-Stack Proficiency`,
            tags: [
              'SDLC',
              'Overview Of MERN',
              'Dev Tools',
              'Node.js Fundamentals',
              'Express Framework',
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 2',
        color: '#FFDBD3',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Stepping Into The World Of Web',
        'Build strong foundation in Web Dev',
        'Hone Skills in App Development',
        'Gain confidence by Practicing Questions',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year2.svg',
    },
  },
  {
    id: '3000',
    yearName: 'Specialisation Year',
    tagLine: 'Empowering Skills For The Digital Era',
    milestones: [
      {
        id: '3001',
        ref: 'Ref5',
        milestone: 'Milestone 5',
        heading: 'Getting Started with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack In Cloud',
            id: '4100',
            cards: [
              {
                heading: 'Web App Architectures',
                icon: '/images/campusEdgeCourseDescription/web/web-app-arch.svg',
                text: `Explore Web App Architectures: Understand Monolithic, Microservices, Serverless & SPA Approaches for Scalable Web Apps`,
                tags: [
                  'Monolithic',
                  'Microservices',
                  'Single Page Apps',
                  'Progressive Web Apps',
                  'Serverless',
                ],
              },
              {
                heading: 'Cloud Concepts',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Explore Cloud Concepts: Learn Cloud Models, Services (AWS/Azure/GCP), Deployment, Scalability & Cost Optimisation`,
                tags: [
                  'Explore Cloud Concepts',
                  'Learn Cloud Models',
                  'Services (AWS/Azure/GCP)',
                  'Deployment',
                  'Scalability & Cost Optimisation',
                ],
              },
              {
                heading: 'Front-End Development Using React',
                icon: '/images/campusEdgeCourseDescription/web/react.svg',
                text: `Master React Front-End Development: Learn React Components, State Management, Routing, API Integration & Interactive UIs`,
                tags: [
                  'React Basics',
                  'React-Router',
                  'Redux Toolkit',
                  'State Management',
                  'Routing and Navigation',
                ],
              },
              {
                heading: 'Deploy A Front-End App',
                icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
                text: `Build & Deploy Front-End Apps on Cloud Platforms, Domain Setup, ENV Variables, Optimisation & More`,
                tags: ['Build & Deploy Front-End App on Versal', 'Netlify', 'AWS', 'Azure', 'etc.'],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Java Advanced concepts',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Master Backend Technologies: Learn JDBC, Hibernate, JPA, Servlet, and MySQL for building robust and scalable applications.',
                tags: ['Knowledge About DB/ORM & Servlet', 'Relational Database Concepts', 'JDBC'],
              },
              {
                heading: 'Java Build Tools',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Explore Essential Dev Tools: Dive into DB, Git, Spring, Spring Boot & more to empower your software development journey.',
                tags: [
                  'Develop Skills On How To Use Tools',
                  'Database Management Systems (DBMS)',
                  'Git Basics',
                ],
              },
              {
                heading: 'Introduction To Spring Framework',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Learn Spring Frame Work Including Spring Core, Spring DataJPA, Spring Rest & Microservices',
                tags: ['Learn Real-Time Projects Via MVC Approach', 'Bean Lifecycle and Scopes'],
              },
              {
                heading: 'Getting Started With Spring Boot',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Spring Boot Project Setup: Master setting up project files & importing in IDE for seamless development.',
                tags: [
                  'Understand Spring Boot',
                  'Choose Dependencies',
                  'IDE Integration',
                  'Application Configuration',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4300', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Python Libraries',
                icon: '/images/campusEdgeCourseDescription/dataScience/python.svg',
                text: "Maximize Python's Power! Learn NumPy, Pandas, Matplotlib, Seaborn, Beautiful Soup, Requests, Flask, Fastai & More",
                tags: [
                  'NumPy',
                  'Pandas',
                  'Matplotlib',
                  'Seaborn',
                  'Requests',
                  'Beautiful Soup',
                  'Web Interactions',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Build A Solid Math Foundation For Data Science: Arithmetic, Algebra & Geometry. Perfect For Beginners.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Discover Statistics: Your Gateway To Data Insights. Master The Essentials For Data-Driven Decisions',
                tags: [
                  'Statistics',
                  'Probability',
                  'Hypothesis Testing',
                  'Regression Analysis',
                  'ANOVA',
                  'Data Analysis',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Model Tuning & Deployment With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4400', // Replace with the actual ID
            cards: [
              {
                heading: 'SQL',
                icon: '/images/campusEdgeCourseDescription/dbms/sql.svg',
                text: 'Master SQL For Data Analytics - Basic To Advanced. Learn MySQL, Interact With Databases & Make Data-Driven Decisions',
                tags: [
                  'SQL Commands',
                  'SQL Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Data Analysis Mastery: Build strong math foundations in Arithmetic, Algebra & Geometry for advanced data insights.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Data Analytics & Statistics: Acquire crucial statistical skills to derive meaningful insights from data.',
                tags: [
                  'Statistics',
                  'Probability',
                  'Probability Distributions',
                  'Inferential Statistics',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Evaluation & Optimisation With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '3002',
        ref: 'Ref6',
        milestone: 'Milestone 6',
        heading: 'Getting Started with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '3100',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Explore Advanced Database Concepts: Optimise, Index, Transact, Shard & Replicate Data For Sophisticated Apps`,
                tags: ['NoSQL Databases - MongoDB', 'Database Concepts', 'Database Optimization'],
              },
              {
                heading: 'Back End Development Using Express',
                icon: '/images/campusEdgeCourseDescription/misc/express.svg',
                text: `Learn Express.js For Back End: Routing, Middleware, DB Integration, Authentication & RESTful API Creation For Robust Servers`,
                tags: ['Express.js - Minimal Node.js and Flexible Node.js Web App Framework'],
              },
              {
                heading: 'Integration Of Back End with Front End',
                icon: '/images/campusEdgeCourseDescription/misc/integration.svg',
                text: `Learn Back End-Front End Integration: Connect Back End (Express/Node.js) to React Front End For Seamless Data Exchange & Interaction`,
                tags: ['Axios - Promise Based HTTP Client For The Browser & Node.js skills'],
              },
              {
                heading: 'Deploying The App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Build & Deploy Back end Apps on Cloud Platforms, Domain Setup, Environment Variables Setup, Optimisation techniques & more`,
                tags: ['Build & Deployment Back end App on Railway, Render, AWS, Azure, etc.'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Capstone: Develop MERN App, Showcase Skills In Front End, Back End, Databases & Deployment`,
                tags: [
                  'Practical Exposure To MERN',
                  'MongoDB',
                  'Express.js',
                  'React',
                  'Node.js skills',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Backend development Java',
            id: '3200',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Learn About JDBC Object-Relational Mapping (ORM), Connection Pooling, Caching & Knowing Advanced Concepts Of DB`,
                tags: [
                  'Thorough Knowledge On DBMS & Object-Relational Mapping (ORM)',
                  'Batch Processing,',
                ],
              },
              {
                heading: 'Developement Of Restful APIs',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Learn To Create RESTful APIs In Java Using HTTP Methods & URLs For CRUD Operations On Resources`,
                tags: [
                  'Learn To Build Custom API',
                  'RESTful Architecture and Principles',
                  'Java Web Framework',
                ],
              },
              {
                heading: 'Spring Security',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: `Spring Security: Powerful Java Security Framework For Web Apps, Handling Authentication, Authorisation & More`,
                tags: [
                  'Gain Knowledge On Security',
                  'Authentication and Authorization',
                  'User Authentication',
                ],
              },
              {
                heading: 'Deploying App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Deploy A Java App on The Cloud Involving Hosting & Running Your Java App on Cloud Infrastructure Provided By Cloud Service Providers`,
                tags: ['Deploying Java Applications', 'Monitoring and Logging', 'Load Balancing'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Java Capstone: Showcase Real-World Skills With A Comprehensive Project, Culminating In Academic Learning`,
                tags: [
                  'Build An Application',
                  'Project Planning and Management',
                  'Programming Proficiency',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '3300',
            cards: [
              {
                heading: 'Machine Learning ',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: `Discover The Potential Of Machine Learning! Build Predictive Models, Explore Data Patterns & Master ML`,
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Feature Engineering',
                  'Hyperparameter Tuning',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Master SQL & MongoDB: Comprehensive Database Course covering Structured & NoSQL Databases for Efficient Data Management',
                tags: [
                  'SQL',
                  'Database Design',
                  'Data Modeling',
                  'Data Manipulation',
                  'MongoDB',
                  'NoSQL & Data Retrieval',
                ],
              },
              {
                heading: 'Time Series',
                icon: '/images/campusEdgeCourseDescription/dataScience/time-series.svg',
                text: 'Explore Time-Dependent Data Analysis & Forecasting with ARIMA, SARIMA, Prophet & More. Master Valuable Insights From Time Series Data.',
                tags: [
                  'Time-Dependent Data',
                  'Forecasting',
                  'Trends',
                  'Seasonality',
                  'ARIMA',
                  'SARIMA',
                ],
              },
              {
                heading: 'Deep Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/deep-learning.svg',
                text: 'Immerse In Deep Learning: Neural Networks, CNNs, RNNs, Transformers, Pre-Trained Models & More',
                tags: [
                  'Neural Networks',
                  'Activation Functions',
                  'Backpropagation',
                  'CNNs',
                  'RNNs',
                  'Transfer Learning',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'Immerse in Neural Machine Translation: Build Powerful Language Systems, Train Transformers, Enhance Multilingual Communication',
                tags: [
                  'Multilingual Data',
                  'Model Evaluation & Building Language Translation Systems',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '3400',
            cards: [
              {
                heading: 'Machine Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Empower With ML: Master Supervised & Unsupervised Learning, Hyperparameter Tuning, Model Development & Serving',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Learn MySql: SQL Basics, Data Types, Loading, Constraints, Joins, Functions, Views, Triggers & more',
                tags: [
                  'SQL Commands',
                  'Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Business Intelligence Tools',
                icon: '/images/campusEdgeCourseDescription/dataScience/business-intelligence.svg',
                text: 'Master Data Visualization: Harness the power of Power BI & Tableau for impactful data presentations.',
                tags: [
                  'Data Visualization',
                  'Data Preparation',
                  'Data Blending',
                  'Dashboard Creation',
                  'Data Exploration',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'In The Capstone Project, Apply All Acquired Skills To Work On An Industry-Relevant Project',
                tags: ['Industry-Relevant Project', 'Hands-On Learning & Becoming Industry-Ready'],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 3',
        color: '#C9F1FE',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Proficiency in specialization',
        'Choose from the 4 available options',
        'Work on End to End Projects',
        'Live Learning',
        'Practice Questions from the experts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year3.svg',
    },
  },
];

const fourYearCourse: Year[] = [
  {
    id: 'sarvottam-4-year-journey',
    yearName: 'Foundation Year',
    tagLine: 'Building Strong Foundations in Java Programming',
    milestones: [
      {
        id: '1001',
        ref: 'Ref1',
        milestone: 'Milestone 1',
        heading: 'Getting Started with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'JAVA',
            id: '1100',
            cards: [
              {
                heading: 'Computer Fundamentals',
                icon: '/images/campusEdgeCourseDescription/misc/computer-fundamentals.svg',
                text: `Learn Computer Concepts, Programming History & Build A Strong Understanding Of Programming`,
                tags: [
                  'Random Access Memory / Read Only Memory',
                  'Memory Storage',
                  'Programming History',
                ],
              },
              {
                heading: 'Getting Started With Programming',
                icon: '/images/campusEdgeCourseDescription/misc/getting-started-with-programming.svg',
                text: `Embark on your Java journey: Explore its rich history, discover its market impact, and embrace its enduring importance.`,
                tags: [
                  'Mastering the WORA (Write Once Run Anywhere) Concepts',
                  'JAVA history',
                  'Market Impact',
                ],
              },
              {
                heading: 'JAVA Core',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: `Master Core Java: Gain expertise in syntax and programming concepts to build a strong foundation in Java development.`,
                tags: [
                  'Java Syntax',
                  'Core Java Insights',
                  'Build Strong Foundation',
                  'Java Programming Concepts',
                ],
              },
              {
                heading: 'Core Data Structure',
                icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
                text: `Boost Logical Thinking & Optimize Programs: Explore DSA to enhance programming skills and master the art of program optimization.`,
                tags: [
                  'Core Data Structure',
                  'Optimized Code',
                  'Logical Ability',
                  'Problem-Solving Skills',
                ],
              },
              {
                heading: 'Git Tool',
                icon: '/images/campusEdgeCourseDescription/git/git-tool.svg',
                text: `Explore Industrial Project Sharing & File Storage Repo: Learn how to manage Java files efficiently for collaborative development. `,
                tags: [
                  'Java File Management',
                  'Java Code Collaboration',
                  'Industrial Project Sharing',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1002',
        ref: 'Ref2',
        milestone: 'Milestone 2',
        heading: 'Unlocking DSA with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'JAVA',
            id: '4100',
            cards: [
              {
                heading: 'Linear Data Structures',
                icon: '/images/campusEdgeCourseDescription/dsa/linear.svg',
                text: `Dive into Arrays, Linked Lists, Stacks, Queues & More: Explore key data structures and topics for comprehensive programming skills.`,
                tags: [
                  'Arrays in Java',
                  'Stack',
                  'Queue',
                  'Linked List',
                  'Hashmap',
                  'Explore Linear Data Structures',
                ],
              },
              {
                heading: 'Non-Linear Data Structures',
                icon: '/images/campusEdgeCourseDescription/dsa/non-linear.svg',
                text: `Master Tree Data Structures & Non-Linear Concepts: Gain insights with examples for a solid grasp of advanced data structures.`,
                tags: [
                  'Tree',
                  'Graphs',
                  'Minheap',
                  'Maxheap',
                  'Understanding Basic Data Structures',
                  'B-Trees',
                ],
              },
              {
                heading: 'Advanced Algorithms',
                icon: '/images/campusEdgeCourseDescription/dsa/advanced-algo.svg',
                text: `Explore Graphs & Dynamic Programming: Learn advanced topics for solving complex problems with efficiency and elegance. `,
                tags: [
                  'Greedy Algo',
                  'Dynamic Programming',
                  'Graph Representation',
                  'Network Flow Algorithms',
                ],
              },
              {
                heading: 'DSA In Practice',
                icon: '/images/campusEdgeCourseDescription/dsa/dsa.svg',
                text: `Sharpen Problem-Solving Skills: Tackle LeetCode challenges to enhance your coding abilities and crack coding interviews.`,
                tags: [
                  'Interview Questions',
                  'Leetcode',
                  'Live Demonstrations',
                  'Problem Decomposition',
                ],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 1',
        color: '#94BE8A',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Gain Expertise In JAVA Programming',
        'Learn from Basics',
        'Master Data Structures',
        'Practice Questions prepared by Experts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year1.svg',
    },
  },
  {
    id: '2000',
    yearName: 'Advanced Year',
    tagLine: 'Transform Ideas Into Interactive Experiences',
    milestones: [
      {
        id: '2001',
        ref: 'Ref3',
        milestone: 'Milestone 3',
        heading: 'Getting Started With Web Dev',
        cards: [
          {
            heading: 'Operating Systems',
            icon: '/images/campusEdgeCourseDescription/misc/computer-fundamentals.svg',
            text: `Master OS Essentials: Processes, Memory, Files, Concurrency For Advanced System Admin Skills`,
            tags: [
              'Process Management',
              'Memory Management',
              'Concurrency Management',
              'Deadlock',
              'File System',
            ],
          },
          {
            heading: 'Networking',
            icon: '/images/campusEdgeCourseDescription/web/networking.svg',
            text: `Explore Networking Basics: Protocols, IP Addressing, Routing, DNS & Security To Set up & Manage Robust Networks`,
            tags: ['OSI and TCP/IP', 'Different Layers Of OSI Model', 'Network Protocols'],
          },
          {
            heading: 'Data bases - RDBMS',
            icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
            text: `Learn RDBMS Fundamentals: Database Design, Normalisation, Transactions & Data Management For Data-Driven Apps`,
            tags: [
              'Relational Databases',
              'Schemas',
              'ER Model',
              'Normalization',
              'ACID Transactions',
            ],
          },
          {
            heading: 'SQL Queries',
            icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
            text: `Create & Perform Operations On Relational Databases: Insert, Update, Delete, JOINS, etc.`,
            tags: ['Different SQL Commands - DDL, DML, DCL', 'CRUD Operations', 'Querying Data'],
          },
          {
            heading: 'Web Applications',
            icon: '/images/campusEdgeCourseDescription/web/web-app.svg',
            text: `Web App Dev: Front-End (HTML, CSS, JS), Back-End (Node.js), Databases, Deployment - Building Dynamic Sites`,
            tags: [
              'Web Application Tools & Languages',
              'Front-End Development',
              'Back-End Development (Node.js)',
            ],
          },
        ],
      },
      {
        id: '2002',
        ref: 'Ref4',
        milestone: 'Milestone 4',
        heading: 'Exploring Web and App Development',
        cards: [
          {
            heading: 'HTML',
            icon: '/images/campusEdgeCourseDescription/web/html.svg',
            text: `Explore HTML Essentials: Structure, Tags, Forms, Multimedia & Semantics To Build Web Pages`,
            tags: [
              'Basic HTML Structure',
              'Web Accessibility and SEO',
              'HTML Tags and Elements',
              'HTML5 Features',
            ],
          },
          {
            heading: 'CSS',
            icon: '/images/campusEdgeCourseDescription/web/css.svg',
            text: `Master CSS: Selectors, Colors, Typography, Positioning, Layout, Responsive Design & More`,
            tags: ['CSS Basics', 'UI Frameworks', 'Styling Elements', 'Layout and Positioning'],
          },
          {
            heading: 'JS',
            icon: '/images/campusEdgeCourseDescription/web/js.svg',
            text: `Learn Interactive Web Programming With JavaScript - Basic To Advanced (DOM, Prototypes, Classes)`,
            tags: ['JavaScript Programming Concepts', 'DOM Manipulation', 'Web APIs Skills'],
          },
          {
            heading: 'Node.js',
            icon: '/images/campusEdgeCourseDescription/web/node.svg',
            text: `Master Node.js: Web APIs, Real-Time Comm., Build Scalable Apps & Server-Side JS Course`,
            tags: [
              'Server',
              'MVC Architecture',
              'Node Modules (Path, OS, etc.)',
              'Creating REST APIs',
            ],
          },
          {
            heading: 'App Development',
            icon: '/images/campusEdgeCourseDescription/web/app-dev.svg',
            text: `Explore MERN App Dev: Plan, Design & Code Web Apps Using MongoDB, Express, React, & Node.js For Full-Stack Proficiency`,
            tags: [
              'SDLC',
              'Overview Of MERN',
              'Dev Tools',
              'Node.js Fundamentals',
              'Express Framework',
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 2',
        color: '#FFDBD3',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Stepping Into The World Of Web',
        'Build strong foundation in Web Dev',
        'Hone Skills in App Development',
        'Gain confidence by Practicing Questions',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year2.svg',
    },
  },
  {
    id: '3000',
    yearName: 'Specialisation Year',
    tagLine: 'Empowering Skills For The Digital Era',
    milestones: [
      {
        id: '3001',
        ref: 'Ref5',
        milestone: 'Milestone 5',
        heading: 'Getting Started with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack In Cloud',
            id: '4100',
            cards: [
              {
                heading: 'Web App Architectures',
                icon: '/images/campusEdgeCourseDescription/web/web-app-arch.svg',
                text: `Explore Web App Architectures: Understand Monolithic, Microservices, Serverless & SPA Approaches for Scalable Web Apps`,
                tags: [
                  'Monolithic',
                  'Microservices',
                  'Single Page Apps',
                  'Progressive Web Apps',
                  'Serverless',
                ],
              },
              {
                heading: 'Cloud Concepts',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Explore Cloud Concepts: Learn Cloud Models, Services (AWS/Azure/GCP), Deployment, Scalability & Cost Optimisation`,
                tags: [
                  'Explore Cloud Concepts',
                  'Learn Cloud Models',
                  'Services (AWS/Azure/GCP)',
                  'Deployment',
                  'Scalability & Cost Optimisation',
                ],
              },
              {
                heading: 'Front-End Development Using React',
                icon: '/images/campusEdgeCourseDescription/web/react.svg',
                text: `Master React Front-End Development: Learn React Components, State Management, Routing, API Integration & Interactive UIs`,
                tags: [
                  'React Basics',
                  'React-Router',
                  'Redux Toolkit',
                  'State Management',
                  'Routing and Navigation',
                ],
              },
              {
                heading: 'Deploy A Front-End App',
                icon: '/images/campusEdgeCourseDescription/dbms/core-data-structure.svg',
                text: `Build & Deploy Front-End Apps on Cloud Platforms, Domain Setup, ENV Variables, Optimisation & More`,
                tags: ['Build & Deploy Front-End App on Versal', 'Netlify', 'AWS', 'Azure', 'etc.'],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Java Advanced concepts',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Master Backend Technologies: Learn JDBC, Hibernate, JPA, Servlet, and MySQL for building robust and scalable applications.',
                tags: ['Knowledge About DB/ORM & Servlet', 'Relational Database Concepts', 'JDBC'],
              },
              {
                heading: 'Java Build Tools',
                icon: '/images/campusEdgeCourseDescription/java/java.svg',
                text: 'Explore Essential Dev Tools: Dive into DB, Git, Spring, Spring Boot & more to empower your software development journey.',
                tags: [
                  'Develop Skills On How To Use Tools',
                  'Database Management Systems (DBMS)',
                  'Git Basics',
                ],
              },
              {
                heading: 'Introduction To Spring Framework',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Learn Spring Frame Work Including Spring Core, Spring DataJPA, Spring Rest & Microservices',
                tags: ['Learn Real-Time Projects Via MVC Approach', 'Bean Lifecycle and Scopes'],
              },
              {
                heading: 'Getting Started With Spring Boot',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: 'Spring Boot Project Setup: Master setting up project files & importing in IDE for seamless development.',
                tags: [
                  'Understand Spring Boot',
                  'Choose Dependencies',
                  'IDE Integration',
                  'Application Configuration',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4300', // You can replace this with the actual ID
            cards: [
              {
                heading: 'Python Libraries',
                icon: '/images/campusEdgeCourseDescription/dataScience/python.svg',
                text: "Maximize Python's Power! Learn NumPy, Pandas, Matplotlib, Seaborn, Beautiful Soup, Requests, Flask, Fastai & More",
                tags: [
                  'NumPy',
                  'Pandas',
                  'Matplotlib',
                  'Seaborn',
                  'Requests',
                  'Beautiful Soup',
                  'Web Interactions',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Build A Solid Math Foundation For Data Science: Arithmetic, Algebra & Geometry. Perfect For Beginners.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Discover Statistics: Your Gateway To Data Insights. Master The Essentials For Data-Driven Decisions',
                tags: [
                  'Statistics',
                  'Probability',
                  'Hypothesis Testing',
                  'Regression Analysis',
                  'ANOVA',
                  'Data Analysis',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Model Tuning & Deployment With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4400', // Replace with the actual ID
            cards: [
              {
                heading: 'SQL',
                icon: '/images/campusEdgeCourseDescription/dbms/sql.svg',
                text: 'Master SQL For Data Analytics - Basic To Advanced. Learn MySQL, Interact With Databases & Make Data-Driven Decisions',
                tags: [
                  'SQL Commands',
                  'SQL Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Mathematics',
                icon: '/images/campusEdgeCourseDescription/dataScience/mathematics.svg',
                text: 'Data Analysis Mastery: Build strong math foundations in Arithmetic, Algebra & Geometry for advanced data insights.',
                tags: [
                  'Arithmetic Fractions',
                  'Decimals',
                  'Percentages',
                  'Ratios',
                  'Linear Equations',
                  'Polynomials',
                ],
              },
              {
                heading: 'Statistics',
                icon: '/images/campusEdgeCourseDescription/dataScience/statistics.svg',
                text: 'Data Analytics & Statistics: Acquire crucial statistical skills to derive meaningful insights from data.',
                tags: [
                  'Statistics',
                  'Probability',
                  'Probability Distributions',
                  'Inferential Statistics',
                ],
              },
              {
                heading: 'Machine Learning Core',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Evaluation & Optimisation With Flask & FastAPI',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '3002',
        ref: 'Ref6',
        milestone: 'Milestone 6',
        heading: 'Getting Started with Programming',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '3100',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Explore Advanced Database Concepts: Optimise, Index, Transact, Shard & Replicate Data For Sophisticated Apps`,
                tags: ['NoSQL Databases - MongoDB', 'Database Concepts', 'Database Optimization'],
              },
              {
                heading: 'Back End Development Using Express',
                icon: '/images/campusEdgeCourseDescription/misc/express.svg',
                text: `Learn Express.js For Back End: Routing, Middleware, DB Integration, Authentication & RESTful API Creation For Robust Servers`,
                tags: ['Express.js - Minimal Node.js and Flexible Node.js Web App Framework'],
              },
              {
                heading: 'Integration Of Back End with Front End',
                icon: '/images/campusEdgeCourseDescription/misc/integration.svg',
                text: `Learn Back End-Front End Integration: Connect Back End (Express/Node.js) to React Front End For Seamless Data Exchange & Interaction`,
                tags: ['Axios - Promise Based HTTP Client For The Browser & Node.js skills'],
              },
              {
                heading: 'Deploying The App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Build & Deploy Back end Apps on Cloud Platforms, Domain Setup, Environment Variables Setup, Optimisation techniques & more`,
                tags: ['Build & Deployment Back end App on Railway, Render, AWS, Azure, etc.'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Capstone: Develop MERN App, Showcase Skills In Front End, Back End, Databases & Deployment`,
                tags: [
                  'Practical Exposure To MERN',
                  'MongoDB',
                  'Express.js',
                  'React',
                  'Node.js skills',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Backend development Java',
            id: '3200',
            cards: [
              {
                heading: 'Advanced Database Concepts',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: `Learn About JDBC Object-Relational Mapping (ORM), Connection Pooling, Caching & Knowing Advanced Concepts Of DB`,
                tags: [
                  'Thorough Knowledge On DBMS & Object-Relational Mapping (ORM)',
                  'Batch Processing,',
                ],
              },
              {
                heading: 'Developement Of Restful APIs',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Learn To Create RESTful APIs In Java Using HTTP Methods & URLs For CRUD Operations On Resources`,
                tags: [
                  'Learn To Build Custom API',
                  'RESTful Architecture and Principles',
                  'Java Web Framework',
                ],
              },
              {
                heading: 'Spring Security',
                icon: '/images/campusEdgeCourseDescription/java/spring.svg',
                text: `Spring Security: Powerful Java Security Framework For Web Apps, Handling Authentication, Authorisation & More`,
                tags: [
                  'Gain Knowledge On Security',
                  'Authentication and Authorization',
                  'User Authentication',
                ],
              },
              {
                heading: 'Deploying App On Cloud',
                icon: '/images/campusEdgeCourseDescription/misc/cloud.svg',
                text: `Learn To Deploy A Java App on The Cloud Involving Hosting & Running Your Java App on Cloud Infrastructure Provided By Cloud Service Providers`,
                tags: ['Deploying Java Applications', 'Monitoring and Logging', 'Load Balancing'],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: `Java Capstone: Showcase Real-World Skills With A Comprehensive Project, Culminating In Academic Learning`,
                tags: [
                  'Build An Application',
                  'Project Planning and Management',
                  'Programming Proficiency',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '3300',
            cards: [
              {
                heading: 'Machine Learning ',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: `Discover The Potential Of Machine Learning! Build Predictive Models, Explore Data Patterns & Master ML`,
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Feature Engineering',
                  'Hyperparameter Tuning',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Master SQL & MongoDB: Comprehensive Database Course covering Structured & NoSQL Databases for Efficient Data Management',
                tags: [
                  'SQL',
                  'Database Design',
                  'Data Modeling',
                  'Data Manipulation',
                  'MongoDB',
                  'NoSQL & Data Retrieval',
                ],
              },
              {
                heading: 'Time Series',
                icon: '/images/campusEdgeCourseDescription/dataScience/time-series.svg',
                text: 'Explore Time-Dependent Data Analysis & Forecasting with ARIMA, SARIMA, Prophet & More. Master Valuable Insights From Time Series Data.',
                tags: [
                  'Time-Dependent Data',
                  'Forecasting',
                  'Trends',
                  'Seasonality',
                  'ARIMA',
                  'SARIMA',
                ],
              },
              {
                heading: 'Deep Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/deep-learning.svg',
                text: 'Immerse In Deep Learning: Neural Networks, CNNs, RNNs, Transformers, Pre-Trained Models & More',
                tags: [
                  'Neural Networks',
                  'Activation Functions',
                  'Backpropagation',
                  'CNNs',
                  'RNNs',
                  'Transfer Learning',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'Immerse in Neural Machine Translation: Build Powerful Language Systems, Train Transformers, Enhance Multilingual Communication',
                tags: [
                  'Multilingual Data',
                  'Model Evaluation & Building Language Translation Systems',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '3400',
            cards: [
              {
                heading: 'Machine Learning',
                icon: '/images/campusEdgeCourseDescription/dataScience/ml.svg',
                text: 'Empower With ML: Master Supervised & Unsupervised Learning, Hyperparameter Tuning, Model Development & Serving',
                tags: [
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Model Evaluation',
                  'Feature Engineering',
                ],
              },
              {
                heading: 'Databases',
                icon: '/images/campusEdgeCourseDescription/dbms/data-base.svg',
                text: 'Learn MySql: SQL Basics, Data Types, Loading, Constraints, Joins, Functions, Views, Triggers & more',
                tags: [
                  'SQL Commands',
                  'Data Types',
                  'Data Loading',
                  'Operators',
                  'Constraints',
                  'Advanced Constraints',
                ],
              },
              {
                heading: 'Business Intelligence Tools',
                icon: '/images/campusEdgeCourseDescription/dataScience/business-intelligence.svg',
                text: 'Master Data Visualization: Harness the power of Power BI & Tableau for impactful data presentations.',
                tags: [
                  'Data Visualization',
                  'Data Preparation',
                  'Data Blending',
                  'Dashboard Creation',
                  'Data Exploration',
                ],
              },
              {
                heading: 'Capstone Project',
                icon: '/images/campusEdgeCourseDescription/misc/capstone.svg',
                text: 'In The Capstone Project, Apply All Acquired Skills To Work On An Industry-Relevant Project',
                tags: ['Industry-Relevant Project', 'Hands-On Learning & Becoming Industry-Ready'],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 3',
        color: '#C9F1FE',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Proficiency in specialization',
        'Choose from the 4 available options',
        'Work on End to End Projects',
        'Live Learning',
        'Practice Questions from the experts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year3.svg',
    },
  },
  {
    id: '4000',
    yearName: 'Achievement Year',
    tagLine: 'Your Gateway To Success: Revise, Refine, Excel',
    milestones: [
      {
        id: '4001',
        ref: 'Ref5',
        milestone: 'Milestone 7',
        heading: 'Getting Upskilled',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees. Ace interviews & Boost Problem-Solving.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Web Dev Mastery: Delve into core concepts, ace interviews, and excel in the world of web development.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },

              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees covered.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Web Dev Excellence: Dive into core concepts, ace interviews, and excel in your domain.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },

              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees. Ace interviews & Boost Problem-Solving.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Dive Into Web Dev, Master Core Concepts & Ace Interviews With Practice. Excel In Your Domain.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },
              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4200',
            cards: [
              {
                heading: 'DSA Interview Questions',
                icon: '/images/campusEdgeCourseDescription/misc/interview-questions.svg',
                text: `Master Data Structures & Algorithms: Arrays, Strings, Recursion, Hashing, Trees. Ace interviews & Boost Problem-Solving.`,
                tags: [
                  'DataStructures',
                  'Algorithms',
                  'Arrays',
                  'Strings',
                  'Recursion',
                  'Hashing',
                  'Trees',
                  'InterviewPrep',
                ],
              },
              {
                heading: 'Core Modules',
                icon: '/images/campusEdgeCourseDescription/misc/core.svg',
                text: `Delve Into The Intricacies Of Data Analytics & Practice A Wide Array Of Interview Questions.`,
                tags: [
                  'Prepare You To Ace Core Module Questions In Interviews',
                  'Technical Question Readiness',
                ],
              },
              {
                heading: 'Soft Skills Development',
                icon: '/images/campusEdgeCourseDescription/misc/soft-skills.svg',
                text: `Boost Employability: Learn Interpersonal & Communication Skills, Teamwork, Problem-Solving & Leadership for Career Growth.`,
                tags: [
                  'Interpersonal Skills',
                  'Communication',
                  'Teamwork',
                  'Problem-Solving',
                  'Leadership',
                  'Career Growth',
                ],
              },
              {
                heading: 'Profile Building',
                icon: '/images/campusEdgeCourseDescription/misc/profile.svg',
                text: `Learn How To Craft Impressive & Impactful Resumes, LinkedIn Profiles & Cover Letters, Showcasing Skills, Experiences, & Achievements.`,
                tags: [
                  'Resume Writing',
                  'LinkedIn Profiles',
                  'Cover Letters',
                  'Skills Showcase',
                  'Professional Experiences',
                ],
              },
              {
                heading: 'Aptitude',
                icon: '/images/campusEdgeCourseDescription/misc/apti.svg',
                text: `Enhance Problem-Solving & Critical Thinking: Master Quantitative Reasoning, Logical Reasoning & Numerical Analysis.`,
                tags: [
                  'Critical Thinking',
                  'Problem-Solving',
                  'Quantitative Reasoning',
                  'Logical Reasoning',
                ],
              },
            ],
          },
        ],
      },
      {
        id: '4002',
        ref: 'Ref6',
        milestone: 'Milestone 8',
        heading: 'Getting Job Ready',
        specialisationElectives: [
          {
            specialisationTopic: 'FullStack in Cloud',
            id: '4200',
            cards: [
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Get access to tailored placement opportunities matching your skills and career goals. Unlock your potential!`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Back End Development Java',
            id: '4200',
            cards: [
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Discover placement opportunities that match your skills and career goals, ensuring the perfect fit for your future success.`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Science',
            id: '4200',
            cards: [
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Explore tailored placement opportunities that align with your skills and career goals for a successful future.`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
          {
            specialisationTopic: 'Data Analytics',
            id: '4200',
            cards: [
              {
                heading: 'Placement Assistance',
                icon: '/images/campusEdgeCourseDescription/misc/placement.svg',
                text: `Develop Skills Needed To Successfully Complete Projects In Your Domain Via Comprehensive Training`,
                tags: [
                  'Skill Development',
                  'Comprehensive Training',
                  'Practice',
                  'Placement Assistance',
                ],
              },
              {
                heading: 'Interview Preparation',
                icon: '/images/campusEdgeCourseDescription/misc/interview-prep.svg',
                text: `Dedicated Training In Interview Preparation, Including Mock Interviews & Resume Reviews`,
                tags: [
                  'Interview Readiness',
                  'Mock Interview',
                  'Resume Review',
                  'Placement Readiness',
                ],
              },
              {
                heading: 'Projects In Specialisation',
                icon: '/images/campusEdgeCourseDescription/misc/bulb.svg',
                text: `Unlock your potential with access to personalized placement opportunities aligning with your skills and career aspirations.`,
                tags: [
                  'Industry Relevant Projects',
                  'Market Ready',
                  'Real World Projects',
                  'Placement Opportunity',
                ],
              },
            ],
          },
        ],
      },
    ],
    achievementCard: {
      heading: {
        text: 'Yayy!! You have finished Year 4',
        color: '#F7E0B4',
        icon: '/images/campusEdgeCourseDescription/misc/celebration.svg',
      },
      content: [
        'Empowered for Success',
        'Build Your Professional Profile',
        'Practice Interview Questions',
        'Revisit the concepts',
      ],
      image: '/images/campusEdgeCourseDescription/misc/year4.svg',
    },
  },
];

export default fourYearCourse;

// const milestones12 = [
//   {
//     id: '1001',
//     ref: 'Ref1',
//     milestone: 'Milestone 1',
//     heading: 'Getting Started with Specialization',
//     specialisationElectives: [
//       {
//         specialisationTopic: 'FullStack In Cloud',
//         id: '4100',
//         cards: [
//           {
//             heading: 'Web App Architectures',
//             icon: '/images/courseDescription/course.png',
//             text: `Explore Web App Architectures: Understand Monolithic, Microservices, Serverless & SPA Approaches for Scalable Web Apps`,
//             tags: [
//               'Monolithic',
//               'Microservices',
//               'Single Page Apps',
//               'Progressive Web Apps',
//               'Serverless',
//             ],
//           },
//           {
//             heading: 'Cloud Concepts',
//             icon: '/images/courseDescription/course.png',
//             text: `Explore Cloud Concepts: Learn Cloud Models, Services (AWS/Azure/GCP), Deployment, Scalability & Cost Optimisation`,
//             tags: [
//               'Explore Cloud Concepts',
//               'Learn Cloud Models',
//               'Services (AWS/Azure/GCP)',
//               'Deployment',
//               'Scalability & Cost Optimisation',
//             ],
//           },
//           {
//             heading: 'Front-End Development Using React',
//             icon: '/images/courseDescription/course.png',
//             text: `Master React Front-End Development: Learn React Components, State Management, Routing, API Integration & Interactive UIs`,
//             tags: [
//               'React Basics',
//               'React-Router',
//               'Redux Toolkit',
//               'State Management',
//               'Routing and Navigation',
//             ],
//           },
//           {
//             heading: 'Deploy A Front-End App',
//             icon: '/images/courseDescription/course.png',
//             text: `Build & Deploy Front-End Apps on Cloud Platforms, Domain Setup, ENV Variables, Optimisation & More`,
//             tags: ['Build & Deploy Front-End App on Versal', 'Netlify', 'AWS', 'Azure', 'etc.'],
//           },
//         ],
//       },
//       {
//         specialisationTopic: 'Back End Development Java',
//         id: '4200', // You can replace this with the actual ID
//         cards: [
//           {
//             heading: 'Java Advanced concepts',
//             icon: '/images/courseDescription/course.png',
//             text: 'Master Backend Technologies: Learn JDBC, Hibernate, JPA, Servlet, and MySQL for building robust and scalable applications.',
//             tags: ['Knowledge About DB/ORM & Servlet', 'Relational Database Concepts', 'JDBC'],
//           },
//           {
//             heading: 'Java Build Tools',
//             icon: '/images/courseDescription/course.png',
//             text: 'Explore Essential Dev Tools: Dive into DB, Git, Spring, Spring Boot & more to empower your software development journey.',
//             tags: [
//               'Develop Skills On How To Use Tools',
//               'Database Management Systems (DBMS)',
//               'Git Basics',
//             ],
//           },
//           {
//             heading: 'Introduction To Spring Framework',
//             icon: '/images/courseDescription/course.png',
//             text: 'Learn Spring Frame Work Including Spring Core, Spring DataJPA, Spring Rest & Microservices',
//             tags: ['Learn Real-Time Projects Via MVC Approach', 'Bean Lifecycle and Scopes'],
//           },
//           {
//             heading: 'Getting Started With Spring Boot',
//             icon: '/images/courseDescription/course.png',
//             text: 'Spring Boot Project Setup: Master setting up project files & importing in IDE for seamless development.',
//             tags: [
//               'Understand Spring Boot',
//               'Choose Dependencies',
//               'IDE Integration',
//               'Application Configuration',
//             ],
//           },
//         ],
//       },
//       {
//         specialisationTopic: 'Data Science',
//         id: '4300', // You can replace this with the actual ID
//         cards: [
//           {
//             heading: 'Python Libraries',
//             icon: '/images/courseDescription/course.png',
//             text: "Maximize Python's Power! Learn NumPy, Pandas, Matplotlib, Seaborn, Beautiful Soup, Requests, Flask, Fastai & More",
//             tags: [
//               'NumPy',
//               'Pandas',
//               'Matplotlib',
//               'Seaborn',
//               'Requests',
//               'Beautiful Soup',
//               'Web Interactions',
//             ],
//           },
//           {
//             heading: 'Mathematics',
//             icon: '/images/courseDescription/course.png',
//             text: 'Build A Solid Math Foundation For Data Science: Arithmetic, Algebra & Geometry. Perfect For Beginners.',
//             tags: [
//               'Arithmetic Fractions',
//               'Decimals',
//               'Percentages',
//               'Ratios',
//               'Linear Equations',
//               'Polynomials',
//             ],
//           },
//           {
//             heading: 'Statistics',
//             icon: '/images/courseDescription/course.png',
//             text: 'Discover Statistics: Your Gateway To Data Insights. Master The Essentials For Data-Driven Decisions',
//             tags: [
//               'Statistics',
//               'Probability',
//               'Hypothesis Testing',
//               'Regression Analysis',
//               'ANOVA',
//               'Data Analysis',
//             ],
//           },
//           {
//             heading: 'Machine Learning Core',
//             icon: '/images/courseDescription/course.png',
//             text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Model Tuning & Deployment With Flask & FastAPI',
//             tags: [
//               'Supervised Learning',
//               'Unsupervised Learning',
//               'Model Evaluation',
//               'Feature Engineering',
//             ],
//           },
//         ],
//       },
//       {
//         specialisationTopic: 'Data Analytics',
//         id: '4400', // Replace with the actual ID
//         cards: [
//           {
//             heading: 'SQL',
//             icon: '/images/courseDescription/course.png',
//             text: 'Master SQL For Data Analytics - Basic To Advanced. Learn MySQL, Interact With Databases & Make Data-Driven Decisions',
//             tags: [
//               'SQL Commands',
//               'SQL Data Types',
//               'Data Loading',
//               'Operators',
//               'Constraints',
//               'Advanced Constraints',
//             ],
//           },
//           {
//             heading: 'Mathematics',
//             icon: '/images/courseDescription/course.png',
//             text: 'Data Analysis Mastery: Build strong math foundations in Arithmetic, Algebra & Geometry for advanced data insights.',
//             tags: [
//               'Arithmetic Fractions',
//               'Decimals',
//               'Percentages',
//               'Ratios',
//               'Linear Equations',
//               'Polynomials',
//             ],
//           },
//           {
//             heading: 'Statistics',
//             icon: '/images/courseDescription/course.png',
//             text: 'Data Analytics & Statistics: Acquire crucial statistical skills to derive meaningful insights from data.',
//             tags: [
//               'Statistics',
//               'Probability',
//               'Probability Distributions',
//               'Inferential Statistics',
//             ],
//           },
//           {
//             heading: 'Machine Learning Core',
//             icon: '/images/courseDescription/course.png',
//             text: 'Master Machine Learning: Learn Predictive Models, Algorithms, Evaluation & Optimisation With Flask & FastAPI',
//             tags: [
//               'Supervised Learning',
//               'Unsupervised Learning',
//               'Model Evaluation',
//               'Feature Engineering',
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: '1002',
//     ref: 'Ref2',
//     milestone: 'Milestone 2',
//     heading: 'In-Depth Specialization',
//     specialisationElectives: [
//       {
//         specialisationTopic: 'FullStack In Cloud',
//         id: '4100',
//         cards: [
//           {
//             heading: 'Advanced Database Concepts',
//             icon: '/images/courseDescription/course.png',
//             text: `Explore Advanced Database Concepts: Optimise, Index, Transact, Shard & Replicate Data For Sophisticated Apps`,
//             tags: ['NoSQL Databases - MongoDB', 'Database Concepts', 'Database Optimization'],
//           },
//           {
//             heading: 'Back End Development Using Express',
//             icon: '/images/courseDescription/course.png',
//             text: `Learn Express.js For Back End: Routing, Middleware, DB Integration, Authentication & RESTful API Creation For Robust Servers`,
//             tags: ['Express.js - Minimal Node.js Flexible Node.js Web App Framework'],
//           },
//           {
//             heading: 'Integration Of Back End with Front End',
//             icon: '/images/courseDescription/course.png',
//             text: `Learn Back End-Front End Integration: Connect Back End (Express/Node.js) to React Front End For Seamless Data Exchange & Interaction`,
//             tags: ['Axios - Promise Based HTTP Client Browser & Node.js Skills'],
//           },
//           {
//             heading: 'Deploying The App On Cloud',
//             icon: '/images/courseDescription/course.png',
//             text: `Learn To Build & Deploy Back end Apps on Cloud Platforms, Domain Setup, Environment Variables Setup, Optimisation techniques & more`,
//             tags: ['Build & Deployment Back-End App on Railway', 'Render', 'AWS', 'Azure'],
//           },
//           {
//             heading: 'Capstone Project',
//             icon: '/images/courseDescription/course.png',
//             text: `Capstone: Develop MERN App, Showcase Skills In Front End, Back End, Databases & Deployment`,
//             tags: [
//               'Practical Exposure',
//               'MERN',
//               'MongoDB',
//               'Express.js',
//               'React',
//               'Node.js Skills',
//             ],
//           },
//         ],
//       },
//       {
//         specialisationTopic: 'Back End Development Java',
//         id: '1213', // Replace with the actual ID
//         cards: [
//           {
//             heading: 'Advanced Database Concepts',
//             icon: '/images/courseDescription/course.png',
//             text: 'Learn About JDBC Object-Relational Mapping (ORM), Connection Pooling, Caching & Knowing Advanced Concepts Of DB',
//             tags: ['Thorough Knowledge On DBMS & ORM', 'Batch Processing'],
//           },
//           {
//             heading: 'Developement Of Restful APIs',
//             icon: '/images/courseDescription/course.png',
//             text: 'Learn To Create RESTful APIs In Java Using HTTP Methods & URLs For CRUD Operations On Resources',
//             tags: [
//               'Learn To Build Custom API',
//               'RESTful Architecture and Principles',
//               'Java Web Framework',
//             ],
//           },
//           {
//             heading: 'Spring Security',
//             icon: '/images/courseDescription/course.png',
//             text: 'Spring Security: Powerful Java Security Framework For Web Apps, Handling Authentication, Authorisation & More',
//             tags: [
//               'Gain Knowledge On Security',
//               'Authentication and Authorization',
//               'User Authentication',
//             ],
//           },
//           {
//             heading: 'Deploying App On Cloud',
//             icon: '/images/courseDescription/course.png',
//             text: 'Learn To Deploy A Java App on The Cloud Involving Hosting & Running Your Java App on Cloud Infrastructure Provided By Cloud Service Providers',
//             tags: ['Deploying Java Applications', 'Monitoring and Logging', 'Load Balancing'],
//           },
//           {
//             heading: 'Capstone Project',
//             icon: '/images/courseDescription/course.png',
//             text: 'Java Capstone: Showcase Real-World Skills With A Comprehensive Project, Culminating In Academic Learning',
//             tags: [
//               'Build An Application',
//               'Project Planning and Management',
//               'Programming Proficiency',
//             ],
//           },
//         ],
//       },
//       {
//         specialisationTopic: 'Data Science',
//         id: '1314', // Replace with the actual ID
//         cards: [
//           {
//             heading: 'Machine Learning',
//             icon: '/images/courseDescription/course.png',
//             text: 'Discover The Potential Of Machine Learning! Build Predictive Models, Explore Data Patterns & Master ML',
//             tags: [
//               'Supervised Learning',
//               'Unsupervised Learning',
//               'Feature Engineering',
//               'Hyperparameter Tuning',
//             ],
//           },
//           {
//             heading: 'Databases',
//             icon: '/images/courseDescription/course.png',
//             text: 'Master SQL & MongoDB: Comprehensive Database Course covering Structured & NoSQL Databases for Efficient Data Management',
//             tags: [
//               'SQL',
//               'Database Design',
//               'Data Modeling',
//               'Data Manipulation',
//               'MongoDB',
//               'NoSQL & Data Retrieval',
//             ],
//           },
//           {
//             heading: 'Time Series',
//             icon: '/images/courseDescription/course.png',
//             text: 'Explore Time-Dependent Data Analysis & Forecasting with ARIMA, SARIMA, Prophet & More. Master Valuable Insights From Time Series Data.',
//             tags: [
//               'Time-Dependent Data',
//               'Forecasting',
//               'Trends',
//               'Seasonality',
//               'ARIMA',
//               'SARIMA',
//             ],
//           },
//           {
//             heading: 'Deep Learning',
//             icon: '/images/courseDescription/course.png',
//             text: 'Immerse In Deep Learning: Neural Networks, CNNs, RNNs, Transformers, Pre-Trained Models & More',
//             tags: [
//               'Neural Networks',
//               'Activation Functions',
//               'Backpropagation',
//               'CNNs',
//               'RNNs',
//               'Transfer Learning',
//             ],
//           },
//           {
//             heading: 'Capstone Project',
//             icon: '/images/courseDescription/course.png',
//             text: 'Immerse in Neural Machine Translation: Build Powerful Language Systems, Train Transformers, Enhance Multilingual Communication',
//             tags: ['Multilingual Data', 'Model Evaluation & Building Language Translation Systems'],
//           },
//         ],
//       },
//       {
//         specialisationTopic: 'Data Analytics',
//         id: '1415', // Replace with the actual ID
//         cards: [
//           {
//             heading: 'Machine Learning',
//             icon: '/images/courseDescription/course.png',
//             text: 'Empower With ML: Master Supervised & Unsupervised Learning, Hyperparameter Tuning, Model Development & Serving',
//             tags: [
//               'Supervised Learning',
//               'Unsupervised Learning',
//               'Model Evaluation',
//               'Feature Engineering',
//             ],
//           },
//           {
//             heading: 'Databases',
//             icon: '/images/courseDescription/course.png',
//             text: 'Learn MySql: SQL Basics, Data Types, Loading, Constraints, Joins, Functions, Views, Triggers & more',
//             tags: [
//               'SQL Commands',
//               'Data Types',
//               'Data Loading',
//               'Operators',
//               'Constraints',
//               'Advanced Constraints',
//             ],
//           },
//           {
//             heading: 'Business Intelligence Tools',
//             icon: '/images/courseDescription/course.png',
//             text: 'Master Data Visualization: Harness the power of Power BI & Tableau for impactful data presentations.',
//             tags: [
//               'Data Visualization',
//               'Data Preparation',
//               'Data Blending',
//               'Dashboard Creation',
//               'Data Exploration',
//             ],
//           },
//           {
//             heading: 'Capstone Project',
//             icon: '/images/courseDescription/course.png',
//             text: 'In The Capstone Project, Apply All Acquired Skills To Work On An Industry-Relevant Project',
//             tags: ['Industry-Relevant Project', 'Hands-On Learning & Becoming Industry-Ready'],
//           },
//         ],
//       },
//     ],
//   },
// ];
