import { useState, useEffect } from 'react';

const useLocationInfo = () => {
  const [location, setLocation] = useState({
    origin: '',
    host: '',
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLocation({
        origin: window.location.origin,
        host: window.location.host,
      });
    }
  }, []);

  return location;
};

export default useLocationInfo;
