import { dataLayer } from '../common/dataLayer.utils';

const sendEventOnClick = (clickText: string, section: string) => {
  const clickData = {
    event: 'clicks',
    click_text: clickText,
    section,
  };

  dataLayer(clickData);
};

export default sendEventOnClick;
