/* eslint-disable camelcase */
import { IUserProfileDetails } from '@/interfaces/common';
import { GAevent } from '../firebase.utils';

const sendCareerPathClickGAEvent = (data: {
  userData: IUserProfileDetails;
  click_text: string;
  mega_section: string;
  section: string;
  year: string;
  lesson_id: string;
  lesson_name: string;
  course_id: string;
  course_name: string;
  meet_url: string;
}) => {
  const GAEventData = {
    user_id: data.userData._id,
    user_name: `${data.userData.firstName} ${data.userData.lastName}`,
    click_text: data.click_text,
    page_name: 'career_path_page',
    mega_section: data.mega_section,
    section: data.section,
    year: data.year,
    lesson_id: data.lesson_id,
    lesson_name: data.lesson_name,
    course_id: data.course_id,
    course_name: data.course_name,
    timestamp: new Date().toLocaleString(),
    meet_url: data.meet_url,
  };
  GAevent('sk_career_path_clicks', GAEventData);
};

const sendDoubtAndNoteSubmitGAEvent = (data: {
  userData: IUserProfileDetails;
  course_name: string;
  course_type: string;
  course_id: string;
  lesson_id: string;
  lesson_name: string;
  video_feature: string;
  timestamp: string;
}) => {
  const GAEventData = {
    user_id: data.userData._id,
    user_name: `${data.userData.firstName} ${data.userData.lastName}`,
    user_email: data.userData.email,
    user_phonenumber: data.userData.phoneNumber,
    course_name: data.course_name,
    course_id: data.course_id,
    lesson_id: data.lesson_id,
    lesson_name: data.lesson_name,
    video_feature: data.video_feature,
    timestamp: new Date().toLocaleString(),
    source_page: 'module_journey_page',
  };
  GAevent('sk_note_doubt_submit', GAEventData);
};

const sendClickGAEvent = (data: {
  userData: IUserProfileDetails;
  course_name: string;
  course_id: string;
  lesson_id: string;
  yearId: string;
  clickText: string;
  milestone_id: string;
  question_id: string;
  source_page: string;
}) => {
  const GAEventData = {
    user_id: data.userData._id,
    user_name: `${data.userData.firstName} ${data.userData.lastName}`,
    course_name: data.course_name,
    course_id: data.course_id,
    lesson_id: data.lesson_id,
    year: data.yearId || '',
    click_text: data.clickText,
    milestone_id: data.milestone_id,
    question_id: data.question_id,
    source_page: data.source_page,
    timestamp: new Date().toLocaleString(),
  };

  GAevent('sk_click', GAEventData);
};

const sendMyCourseClickGAEvent = (data: {
  userData: IUserProfileDetails;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
  source_page: string;
}) => {
  const GAEventData = {
    user_id: data.userData._id,
    user_name: `${data.userData.firstName} ${data.userData.lastName}`,
    utm_campaign: data.utm_campaign,
    utm_medium: data.utm_medium,
    utm_source: data.utm_source,
    source_page: data.source_page,
    page_name: 'my_courses_page',
    timestamp: new Date().toLocaleString(),
  };
  GAevent('sk_my_course_click', GAEventData);
};

export {
  sendCareerPathClickGAEvent,
  sendDoubtAndNoteSubmitGAEvent,
  sendClickGAEvent,
  sendMyCourseClickGAEvent,
};
